<template>
  <v-btn
    color="orange lighten-1"
    text
    :x-large="size == 'x-large'"
    @click="buttonClicked"
    :loading="loading"
  >
    <v-icon :large="iconSize == 'large'" v-if="iconName" left>{{
      iconName
    }}</v-icon>
    <span>{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "CardActionButton",
  props: {
    click: {
      type: Function,
    },
    i18ntext: {
      type: String,
    },
    type: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    text: function () {
      var label = null;
      switch (this.type) {
        case "save":
          label = "save";
          break;
        case "close":
          label = "close";
          break;
        case "cancel":
          label = "cancel";
          break;
        case "delete":
          label = "delete";
          break;
        default:
          label = this.i18ntext;
      }
      return this.$i18n.t(label);
    },
    iconName: function () {
      switch (this.type) {
        case "save":
          return "mdi-check";
        case "close":
          return "mdi-close";
        case "cancel":
          return "mdi-cancel";
        case "delete":
          return "mdi-delete";
        default:
          return this.icon;
      }
    },
    size: function () {
      switch (this.type) {
        case "save":
          return "x-large";
        default:
          return null;
      }
    },
    iconSize: function () {
      switch (this.type) {
        case "save":
          return "large";
        default:
          return null;
      }
    },
  },
  methods: {
    buttonClicked() {
      this.$emit("click");
    },
  },
};
</script>