<template>
    <v-btn icon fab @click="$emit('closeClick')"><v-icon large>mdi-close</v-icon></v-btn>
</template>

<script>
export default {
  components: {
    
  },
};
</script>