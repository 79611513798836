import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAlert,{attrs:{"border":"left","colored-border":"","color":_vm.color,"elevation":"1","dense":""}},[_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoreButton || _vm.title || _vm.$scopedSlots.title),expression:"showMoreButton || title || $scopedSlots.title"}],attrs:{"fluid":""}},[_c(VRow,{class:_vm.showMoreButton ? 'row-pointer' : '',attrs:{"no-gutters":""},on:{"click":function($event){_vm.showMoreButton ? _vm.showMore = !_vm.showMore : null}}},[(!_vm.$scopedSlots.title && _vm.title)?_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):(_vm.$scopedSlots.title)?_c('span',[_vm._t("title")],2):_vm._e(),_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoreButton),expression:"showMoreButton"}],attrs:{"icon":""}},[_c(VIcon,[_vm._v(_vm._s(_vm.showMore ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMore),expression:"showMore"}]},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }