<template>
  <v-alert border="left" colored-border :color="color" elevation="1" dense>
    <v-container fluid v-show="showMoreButton || title || $scopedSlots.title"
      ><v-row no-gutters @click="showMoreButton ? showMore = !showMore : null" :class="showMoreButton ? 'row-pointer' : ''">
        <span v-if="!$scopedSlots.title && title" class="title">{{
          title
        }}</span>
        <span v-else-if="$scopedSlots.title">
          <slot name="title"></slot>
        </span>

        <v-spacer />
        <v-btn icon  v-show="showMoreButton">
          <v-icon>{{
            showMore ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn></v-row
      >
    </v-container>
    <v-expand-transition>
      <div v-show="showMore">
        <slot></slot>
      </div>
    </v-expand-transition>
  </v-alert>
</template>

<script>
export default {
  name: "FormGroupElement",
  props: {
    title: String,
    color: {
      type: String,
      default: "primary",
    },
    showMoreButton: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {},
  watch: {
    expanded() {
      this.showMore = this.expanded;
    },
  },
  mounted() {
    this.showMore = this.expanded;
  },
};
</script>